import React, { useState } from "react"
import { Link } from "react-router-dom"
import styles from "./UserMenu.module.scss"
import { ReactComponent as CloseButton } from "../../../icons/close.svg"
import UserAvatar from "./UserAvatar"
import { isMobile } from "react-device-detect"
import {
  band_dashboard_path,
  discover_path,
  faq_path,
  live_now_path,
  new_event_path,
  new_post_path,
  own_bandnada_path,
  sign_out_path,
  user_settings_path,
} from "../../../routes"

const UserMenu = ({ user = null }) => {
  const [showUserMenu, setShowUserMenu] = useState(false)

  user.avatar_url = user.avatar_url_small

  const handleLogout = () => {
    localStorage.removeItem("jwt_token")
    window.location.href = sign_out_path()
  }

  return (
    <div>
      <UserAvatar
        handleClick={() => setShowUserMenu(!showUserMenu)}
        user={user}
        inHeader={true}
        size="small"
      />

      {showUserMenu && (
        <>
          <div className={`sm:hidden ${styles.overlay}`}></div>

          <ul
            onMouseLeave={() => setShowUserMenu(false)}
            className={styles.menu}
          >
            <div className="sm:hidden py-3 px-3 flex justify-end">
              <CloseButton
                onClick={() => setShowUserMenu(false)}
                className="ht-2"
              />
            </div>
            <li
              className={`font-semibold  ${
                user.current_share_total > 0 ? "" : "border-b-2 border-gray-200"
              } ${styles.item}`}
            >
              {user.band ? user.band.name : user.name}
            </li>
            {user.current_share_total > 0 ? (
              <li
                className={`font-semibold border-b-2 border-gray-200 ${styles.item}`}
              >
                {user.current_share_total} shares earned{" "}
                <Link
                  to={own_bandnada_path()}
                  className="text-blue-600 font-normal"
                >
                  (learn more)
                </Link>
              </li>
            ) : null}

            {isMobile && (
              <>
                <li className={styles.link}>
                  <Link className={styles.menu_anchor} to={discover_path()}>
                    Discover
                  </Link>
                </li>
                <li className={`border-b-2 border-gray-200 ${styles.link}`}>
                  <Link className={styles.menu_anchor} to={live_now_path()}>
                    Live Now
                  </Link>
                </li>
              </>
            )}
            {user.band && (
              <>
                <li className={styles.link}>
                  <Link className={styles.menu_anchor} to={user.band.band_path}>
                    View Your Page
                  </Link>
                </li>
                {user.band.band_type != "fan" && (
                  <>
                    <li className={styles.link}>
                      <Link
                        className={styles.menu_anchor}
                        to={user.band.edit_band_path}
                      >
                        Manage Page
                      </Link>
                    </li>
                    <li className={styles.link}>
                      <Link
                        className={styles.menu_anchor}
                        to={band_dashboard_path()}
                      >
                        Band Dashboard
                      </Link>
                    </li>
                    <li className={styles.link}>
                      <Link
                        className={styles.menu_anchor}
                        to={new_event_path()}
                      >
                        Create an Event
                      </Link>
                    </li>
                    <li className={`border-b-2 border-gray-200 ${styles.link}`}>
                      <Link className={styles.menu_anchor} to={new_post_path()}>
                        Create a Post
                      </Link>
                    </li>
                  </>
                )}
              </>
            )}
            <li className={styles.link}>
              <Link className={styles.menu_anchor} to={user_settings_path()}>
                Settings
              </Link>
            </li>
            <li className={styles.link}>
              <Link className={styles.menu_anchor} to={faq_path()}>
                Help / FAQ
              </Link>
            </li>
            <li className={styles.link}>
              <button className={styles.menu_anchor} onClick={handleLogout}>
                Logout
              </button>
            </li>
          </ul>
        </>
      )}
    </div>
  )
}

export default UserMenu
