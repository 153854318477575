import { stringify } from "query-string"

export async function request({ queryKey = null }) {
  const _key = queryKey[0]
  const { path, options, params, data } = queryKey[1]
  const csrfMeta = document.querySelector("meta[name=csrf-token]")
  const csrf = csrfMeta instanceof HTMLMetaElement ? csrfMeta.content : null
  const token = localStorage.getItem("jwt_token")

  const headers = new Headers()
  headers.set("Content-Type", "application/json")
  headers.set("Accept", "application/json")
  if (csrf) headers.set("X-CSRF-Token", csrf)
  if (token) headers.set("Authorization", `Bearer ${token}`)

  var bodyData = JSON.stringify(data)

  const url = `${path}${
    params ? `?${stringify(params, { arrayFormat: "bracket" })}` : ""
  }`

  const response = await fetch(url, {
    headers: headers,
    credentials: "same-origin",
    body: bodyData,
    ...options,
  })
  if (response.status === 500) {
    throw new Error(await tryParseJSON(response))
  } else if (response.status === 404) {
    throw new Error(`404 not found`)
  } else if (response.status === 401) {
    // our token died - kick out to the login page
    window.location.href = "/"
  }
  return await response.json()
}

export async function mutationRequest(query) {
  return request({ queryKey: ["mutation", query] })
}

// parse response as JSON if it is - or return string for error handling
/** @param {Response} response */
async function tryParseJSON(response) {
  let text = await response.text()
  const contentType = response.headers.get("content-type")

  try {
    if (contentType && contentType.includes("application/json")) {
      return JSON.stringify(await response.json(), null, 2)
    } else if (contentType && contentType.includes("text/html")) {
      return `An unknown error occured:  ${response.statusText} (${response.status})`
    } else {
      return await response.text()
    }
  } catch (error) {
    return text
  }
}
