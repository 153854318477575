import React, { useState } from "react"
import { PauseCircle, PlayCircle, PlusCircle } from "react-bootstrap-icons"
import { useDispatch, useSelector } from "react-redux"
import {
  prependAndPlay,
  pushToQueue,
  playOrPause,
} from "../../data/mediaPlayerSlice"

export const SingleTrackItem = ({ song, index }) => {
  const [showLyrics, setShowLyrics] = useState(false)
  const dispatch = useDispatch()
  const currentMediaItem = useSelector(
    // @ts-ignore
    (state) => state.mediaPlayer.value.currentMediaItem,
  )
  // @ts-ignore
  const playerState = useSelector(
    (state) => state.mediaPlayer.value.playerState,
  )
  const isCurrentlyPlaying =
    playerState === "playing" && currentMediaItem.postId === song.id
  const isCurrentlyQueued =
    playerState === "paused" && currentMediaItem.postId === song.id

  const onPlayClick = (e) => {
    dispatch(prependAndPlay(mappedSong()))
  }
  const onPlayOrPauseClick = (e) => {
    dispatch(playOrPause())
  }

  const onAddClick = (e) => {
    dispatch(pushToQueue(mappedSong()))
  }

  const mappedSong = () => {
    return [song].map((song) => ({
      ...song,
    }))
  }

  return (
    <li className="">
      <div className="flex justify-between">
        <div className="flex">
          {isCurrentlyPlaying ? (
            <PauseCircle
              className={`mt-1 mr-1 cursor-pointer`}
              style={{ minWidth: "1rem" }}
              onClick={onPlayOrPauseClick}
            />
          ) : (
            <PlayCircle
              className={`mt-1 mr-1 cursor-pointer`}
              style={{ minWidth: "1rem" }}
              onClick={isCurrentlyQueued ? onPlayOrPauseClick : onPlayClick}
            />
          )}
          <PlusCircle
            className={`mt-1 mr-1 cursor-pointer`}
            style={{ minWidth: "1rem" }}
            onClick={onAddClick}
          />
          <span className="font-bold">{song.track_number || index + 1}.</span>
          &nbsp;
          <span
            className={`${
              song.streaming_url ? "text-gray-700" : "text-gray-400"
            }`}
          >
            {song.title}
          </span>
        </div>
        <div>
          {song.lyrics ? (
            <button
              className="text-xs text-gray-500 focus:outline-none"
              onClick={(e) => setShowLyrics(!showLyrics)}
            >
              {showLyrics ? "hide lyrics" : "show lyrics"}
            </button>
          ) : null}
        </div>
      </div>

      {showLyrics && (
        <div
          className="py-3 text-gray-800 italic"
          dangerouslySetInnerHTML={{
            __html: song.lyrics.replace(/\n/g, "<br/>"),
          }}
        ></div>
      )}
    </li>
  )
}
